export default function nodeListToArray (nodes) {
  var array = null
  try {
    array = Array.prototype.slice.call(nodes, 0) // non-IE and IE9+
  } catch (ex) {
    array = []
    for (var i = 0, len = nodes.length; i < len; i += 1) {
      array.push(nodes[i])
    }
  }
  return array
}
