/* global __NAMESPACE__ */
import map from './helper/map'
import nodeListToArray from './helper/node-list-to-array'
import { fnSendClickEvent, fnSendRenderedEvent, fnSendViewableEvent } from './handle-events'
import logger from './helper/logger'
import uuid from './helper/uuid'

const ATTR_NAME = 'data-ad-events'
const ATTR_UUID = 'data-ad-uuid'

const log = logger('global')

function applyEventHandlerToElement (adElement) {
  log('applyEventHandlerToElement: start')
  try {
    const adEvents = JSON.parse(adElement.getAttribute(ATTR_NAME))
    adElement.removeAttribute(ATTR_NAME)

    const currentEventId = uuid()
    adElement.setAttribute(ATTR_UUID, currentEventId)

    const logFn = logger(adElement.getAttribute(ATTR_UUID))

    fnSendRenderedEvent(adElement, adEvents, logFn)
    fnSendViewableEvent(adElement, adEvents, logFn)
    fnSendClickEvent(adElement, adEvents, logFn)
  } catch (ignore) {
  }
  log('applyEventHandlerToElement: end')
}

function findAdElements (tagName = 'li', attrName = ATTR_NAME) {
  return nodeListToArray(document.querySelectorAll(tagName + '[' + attrName + ']'))
}

function boot () {
  log('boot: start')
  map(applyEventHandlerToElement, findAdElements())
  log('boot: end')
}

boot.findAdElements = findAdElements
boot.applyEventHandlerToElement = applyEventHandlerToElement

function installEventListener () {
// Global Event Listener 설치
  if (!boot.isEventListenerInstalled) {
    log('install onload event listener')
    boot.isEventListenerInstalled = true
    window.addEventListener('load', function _bootstrap () {
      log('fired onload event listener')
      window.removeEventListener('load', _bootstrap)
      boot()
    })
  }
}

function installApiEndpoint () {
  window[__NAMESPACE__] = window[__NAMESPACE__] || boot
}

installEventListener()
installApiEndpoint()
