import noop from './helper/noop'
import map from './helper/map'
import sendBeacon from './helper/send-beacon'
import notifyError from './helper/notify-error'
import createViewableInstance from './create-viewable-instance'

function handleEvents (eventName) {
  return function (events, logFn) {
    events = events || []
    logFn = logFn || noop
    logFn(eventName + ': filtering beacon from Events')

    return Promise.all(
      map(function (e) {
        if (e.type === eventName) {
          logFn(`${eventName}: getting urls from them`)

          var v = e.url

          logFn(eventName + ': sending beacon to ' + v)

          return sendBeacon(v)
            .then(function (beacon) {
              logFn(eventName + ': beacon ' + beacon.src + ' is just sent')
            })
            .catch(notifyError)
        }
      }, events)
    )
  }
}

function addRenderEventListener (fn) {
  return function (targetElement, adEvents, logFn) {
    if (targetElement.getClientRects().length > 0) {
      fn(adEvents, logFn)
    }
  }
}

function addViewableEventListener (fn) {
  return function (targetElement, adEvents, logFn) {
    var viewableInstanceFactory = createViewableInstance(targetElement)

    targetElement._viewableInstance = viewableInstanceFactory(function (containerNode) {
      fn(adEvents, logFn)
    })

    targetElement._viewableInstance.start()
  }
}

function addClickEventListener (fn) {
  return function (targetElement, adEvents, logFn) {
    targetElement && targetElement.addEventListener('click', function () {
      fn(adEvents, logFn)
    }, true)
  }
}

const fnSendRenderedEvent = addRenderEventListener(handleEvents('rendered'))
const fnSendViewableEvent = addViewableEventListener(handleEvents('viewable'))
const fnSendClickEvent = addClickEventListener(handleEvents('click'))

export {
  fnSendRenderedEvent,
  fnSendViewableEvent,
  fnSendClickEvent
}
