/* global Image */

function sendBeacon (url) {
  return new Promise((resolve, reject) => {
    var beacon = new Image()

    beacon.onerror = (ignore) => reject(sendBeacon.defaultReject(url))
    beacon.onload = (ignore) => resolve(sendBeacon.defaultResolve(url))

    beacon.src = url
  })
}

sendBeacon.defaultResolve = (url) => ({ src: url })

sendBeacon.defaultReject = (url) => new Error('Beacon cannot sent', { url })

export default sendBeacon
