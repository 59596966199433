import noop from './helper/noop'

export default function createViewableInstance (element, {
  VIEWABLE_CONTINUOUS_TIME = 1000,
  VIEWABLE_THRESHOLD = 50
} = {}) {
  return function (onLoadCallback) {
    onLoadCallback = onLoadCallback || noop

    const canUseIntersectionObserver =
      'IntersectionObserver' in window &&
      'IntersectionObserverEntry' in window

    const threshold = VIEWABLE_THRESHOLD / 100
    const viewableInstance = {
      _instance: null
    }

    if (canUseIntersectionObserver) {
      let isFiredViewableEvent = false
      let visibleTimeout = null

      const _isViewable = (bcr, icr) => ((icr.width * icr.height) / (bcr.width * bcr.height) >= threshold)

      const _visibleTimerCallback = function (element, observer) {
        _processChanges(observer.takeRecords(), observer)

        if ('isVisible' in element && element.isVisible) {
          delete element.isVisible
          if (!isFiredViewableEvent) {
            isFiredViewableEvent = true
            onLoadCallback(element)
            _releaseTimer(element)
            _releaseInstance(element, observer)
          }
        }
      }

      const _releaseTimer = function (element) {
        if ('isVisible' in element) {
          delete element.isVisible
        }

        if (visibleTimeout) {
          clearTimeout(visibleTimeout)
          visibleTimeout = null
        }
      }

      const _releaseInstance = function (element, observer) {
        observer.unobserve(element)
        observer.disconnect()
      }

      const _processChanges = function (changes, observer) {
        changes.forEach(function (changeRecord) {
          const element = changeRecord.target
          element.isVisible = (_isViewable(changeRecord.boundingClientRect, changeRecord.intersectionRect))
          if ('isVisible' in element && element.isVisible && !isFiredViewableEvent) {
            visibleTimeout = setTimeout(_visibleTimerCallback, VIEWABLE_CONTINUOUS_TIME, element, observer)
          } else {
            _releaseTimer(element)
          }
        })
      }

      viewableInstance._instance = new window.IntersectionObserver(function (entries, observer) {
        _processChanges(entries, observer)
      }, {
        threshold: [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0],
        trackVisibility: true,
        delay: 100
      })

      viewableInstance.start = () => viewableInstance._instance.observe(element)
      viewableInstance.destroy = () => _releaseInstance(element, viewableInstance._instance)
    } else {
      viewableInstance._instance = new ViewableChecker({
        target: element,
        percentOfPixels: VIEWABLE_THRESHOLD,
        minimumAmountOfTime: VIEWABLE_CONTINUOUS_TIME,
        successCallback: function () {
          onLoadCallback(element)
        }
      })

      viewableInstance.start = () => viewableInstance._instance.measure()
      viewableInstance.destroy = () => viewableInstance._instance.destroy()
    }

    return viewableInstance
  }
}

function ViewableChecker () {
  // TODO: IE 트레픽을 위해서 구현하거나 IntersectionObserver Polyfill 해야 함
  return {
    measure: function () {
      // console.error("NOT IMPLEMENTED")
    },
    destroy: function () {
      // console.error("NOT IMPLEMENTED")
    }
  }
}
